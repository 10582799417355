import "./lib/dayjs";
import "./css/All.css";

import i18n from "./i18n";

import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { SnackbarProvider } from "notistack";

import { Redirect } from "react-router-dom";

import { EventProvider } from "./contexts/EventContext";
import { UserProvider } from "./contexts/UserContext";
import { ServerProvider, LoginServerProvider } from "./contexts/ServerContext";
import { ExhibitorProvider } from "./contexts/ExhibitorContext";
import { AuthProvider } from "./contexts/Auth";

import Header from "./components/Header";
import Loading from "./components/Loading";
import RoleRoute from "./components/RoleRoute";
import ExhibitorRoute from "./components/ExhibitorRoute";

import * as Pages from "./pages";
import Route from "./components/Route";

import { BrowserRouter as Router, Switch } from "react-router-dom";

// CI Trigger: 3

const AuthenticatedRoutes = React.memo(() => {
  return (
    <EventProvider>
      <Header />
      <ExhibitorProvider>
        <Switch>
          <Route exact path="/" component={Pages.Dashboard} />
          <Route path="/settings" component={Pages.Settings} />
          <Route
            exact
            path="/changepassword"
            component={Pages.ChangePasswordPage}
          />
          <Route
            exact
            path="/notifications"
            component={Pages.NotificationsPage}
          />
          <Route path="/profile" component={Pages.MyProfile} />
          <ExhibitorRoute
            path="/exhibitor"
            component={Pages.ExposantProfile}
            roles={["EMPLOYEE", "MANAGER", "SPEAKER"]}
          />
          <Redirect from="/exposant/profile" to="/exhibitor" />
          <ExhibitorRoute
            exact
            path="/checklist"
            component={Pages.ExposantProfileCompletion}
            roles={["MANAGER"]}
          />
          <Route
            exact
            path="/gathering/:ID/signup"
            component={Pages.GatheringSignup}
          />
          <ExhibitorRoute
            path="/gathering"
            component={Pages.GatheringOverview}
            roles={["EMPLOYEE", "MANAGER"]}
          />
          <ExhibitorRoute
            path="/services"
            component={Pages.StandServices}
            roles={["MANAGER"]}
          />
          <ExhibitorRoute
            path="/tickets"
            component={Pages.Tickets}
            roles={["MANAGER"]}
          />
          <RoleRoute
            exact
            path="/help/documents"
            component={Pages.DocumentsPage}
            roles={["MANAGER"]}
          />
          <Route path="/agenda" component={Pages.Agenda} />
          <Route path="/help" component={Pages.HelpPage} />
          <Redirect to="/" />
        </Switch>
      </ExhibitorProvider>
    </EventProvider>
  );
});

const Root = React.memo(() => {
  if (process.env.NODE_ENV === "production") {
    return (
      <ServerProvider>
        <UserProvider>
          <Switch>
            <Route.Private path="/" component={AuthenticatedRoutes} />
          </Switch>
        </UserProvider>
      </ServerProvider>
    );
  }

  return (
    <ServerProvider>
      <UserProvider>
        <Switch>
          <Route exact path="/login" component={Pages.Login} />
          <Route.Private path="/" component={AuthenticatedRoutes} />
        </Switch>
      </UserProvider>
    </ServerProvider>
  );
});

const App = React.memo(() => (
  <SnackbarProvider maxSnack={3} anchorOrigin="center" autoHideDuration={3000}>
    <Suspense fallback={<Loading />}>
      <I18nextProvider i18n={i18n}>
        <LoginServerProvider>
          <AuthProvider>
            <Router>
              <Root />
            </Router>
          </AuthProvider>
        </LoginServerProvider>
      </I18nextProvider>
    </Suspense>
  </SnackbarProvider>
));

// <Switch>
//   <Route exact path="/register" component={Pages.Register} />
//   <Route
//     exact
//     path="/register/confirm"
//     component={Pages.RegisterConfirm}
//   />
//   <Route path="/" component={AppRoute} />
// </Switch>

ReactDOM.render(<App />, document.getElementById("root"));
