import React, { Fragment, useState, useMemo } from "react";

import { useMutation } from "@apollo/react-hooks";
import parseHTML from "html-react-parser";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";

import { useTranslation } from "react-i18next";

import {
  Switch,
  Redirect,
  useHistory,
  Link,
  useRouteMatch,
} from "react-router-dom";

import dayjs from "dayjs";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";

import { getMomentFromString } from "../../../lib/common";

/* components */
import Badge from "../../../components/Badge";
import Icon from "../../../components/Icon";
import Notification from "../../../components/Notification";
import DialogBox from "../../../components/Dialog";
import Breadcrumbs from "../../../components/Breadcrumbs";
import CurrentStage from "../../../components/CurrentStage";
import LinkArrowExternal from "../../../components/LinkArrowExternal";

import RoleRoute from "../../../components/RoleRoute";
import MainExhibitorRoute from "../../../components/MainExhibitorRoute";
import Route from "../../../components/Route";

/* context */
import { RoomReservationProvider } from "../../../contexts/RoomReservationContext";
import {
  GatheringProvider,
  useGathering,
  useGatheringContext,
  useGatheringIsEditablePermission,
  useGatheringIsReservationEditable,
} from "../../../contexts/GatheringContext";
import { useAttendeeExhibitor } from "../../../contexts/ExhibitorContext";

/* pages */
import GatheringTimeAndLocation from "../GatheringTimeAndLocation";
import GatheringDescription from "../GatheringDescription";
import GatheringSpeakers from "../GatheringSpeakers";
import GatheringRegistration from "../GatheringRegistration";
import GatheringRegistrationType from "../GatheringRegistrationType";
import GatheringCategories from "../GatheringCategories";
import GatheringEntertainmentRoute from "../Entertainment";
import Speakers from "../Speakers";
import SpeakersInvite from "../SpeakersInvite";

import RoomReservationEdit from "../../RoomReservationEdit";
import RoomReservationOverview from "../../Calendar";
import RoomReservationCancel from "../../RoomReservationOverview/RoomReservationCancel";
import RoomReservationComplete from "../../RoomReservationOverview/RoomReservationComplete";

import { DELETE_GATHERING } from "../../../graphql/Gathering";
import { GET_EXHIBITOR_FOR_DASHBOARD } from "../../../graphql/User";

import DefaultLayout from "../../../layout/DefaultLayout";

import { ENABLE_GATHERING_ATTACHING_SPEAKERS } from "../../../utils/globals";

const CardLink = (props) => {
  const { to, className = "", disabled = true, children } = props;

  if (disabled) {
    return <div className={`card-body ${className}`}>{children}</div>;
  }

  return (
    <Link
      to={to}
      className={`card-body text-decoration-none d-flex flex-row ${className}`}
    >
      <div className="d-flex flex-column flex-grow-1">{children}</div>
      <Icon className="text-primary mx-1" iconType="ArrowDropRight" />
    </Link>
  );
};

const GatheringMenu = ({ onMenuClick }) => {
  const { t } = useTranslation(["common", "gatherings"]);
  const [menuEl, setMenuEl] = useState(null);
  const open = !!menuEl;

  /**
   * Show gathering menu
   */
  const openMenu = (e) => {
    setMenuEl(e.currentTarget);
  };

  /**
   * Close gathering menu
   */
  const closeMenu = () => {
    setMenuEl(null);
  };

  const hasPermission = useGatheringIsEditablePermission();

  if (!hasPermission) {
    return null;
  }

  return (
    <Fragment>
      <Icon className="font-size-1-5" iconType="More" onClick={openMenu} />
      <Menu
        id="fade-menu"
        anchorEl={menuEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            onMenuClick();
          }}
        >
          {t("common:delete")}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const RemoveDialog = ({ open, replace, gathering, onMenuClick }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const exhibitor = useAttendeeExhibitor();

  const [removeGathering, { loading: removing }] = useMutation(
    DELETE_GATHERING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EXHIBITOR_FOR_DASHBOARD,
          variables: {
            ID: exhibitor.ID,
          },
        },
      ],
      onCompleted: () => {
        replace("/gathering");
      },
    }
  );

  const handleRemove = (remove) => {
    onMenuClick();
    if (!remove && !removing) {
      return false;
    }

    removeGathering({
      variables: {
        ID: gathering.ID,
      },
    });
  };

  return (
    <Fragment>
      <DialogBox
        open={open}
        title={t("gatherings:removegathering")}
        content={t("gatherings:removetext", {
          title: gathering.Title
            ? gathering.Title
            : gathering.ID
            ? `#${gathering.ID}`
            : "",
        })}
        onAccept={() => {
          handleRemove(true);
        }}
        onCancel={() => {
          handleRemove(false);
        }}
      />

      <Notification
        type="error"
        content={t("common:removing")}
        open={removing}
        hideAfter={3000}
      />
    </Fragment>
  );
};

const TypeAndTagsList = () => {
  const { t } = useTranslation(["common", "gatherings"]);

  const { data: gathering } = useGathering();

  const Tags = useMemo(
    () => map(get(gathering, "Tags.edges"), "node"),
    [gathering]
  );

  const isEmpty = useMemo(
    () => !Tags.length || !gathering.Type,
    [Tags, gathering.Type]
  );

  if (isEmpty) {
    return <p className="text-gray mb-0">{t("gatherings:categories_empty")}</p>;
  }

  return (
    <div className="d-flex flex-wrap">
      {!!gathering.Type && (
        <Badge className="mb-2 mr-2">
          {t(`gatheringType:${gathering.Type}`)}
        </Badge>
      )}
      {Tags.map(({ ID, Title }) => (
        <Badge secondary className="mb-2 mr-2" key={ID}>
          {Title}
        </Badge>
      ))}
    </div>
  );
};

const AttendeesList = () => {
  const { t } = useTranslation(["common", "gatherings"]);

  const { data: gathering } = useGathering();

  const types = useMemo(() => {
    return {
      PROVADA: t("gatherings:register:provada"),
      EMAIL: t("gatherings:register:emailtext", {
        email: gathering.RegisterOn,
      }),
      EXTERNAL: t("gatherings:register:externaltext", {
        site: gathering.RegisterOn,
      }),
      FREE: t("gatherings:register:free"),
      PRIVATE: t("gatherings:register:private"),
    };
  }, [gathering.RegisterOn, t]);

  const groupedAttendees = useMemo(
    () => groupBy(gathering.Attendees.edges, "node.Status"),
    [gathering.Attendees]
  );

  if (!gathering.Registration) {
    return (
      <p className="text-gray mb-0">{t("gatherings:attendeesinfo_empty")}</p>
    );
  }

  if (!gathering.Attendees.edges.length) {
    return <p className="text-dark mb-0 ">{types[gathering.Registration]}</p>;
  }

  return (
    <p className="mb-0 text-dark">
      {t("gatherings:attendeesinfo", {
        pending: groupedAttendees["-1"] ? groupedAttendees["-1"].length : 0,
        attending: groupedAttendees["1"] ? groupedAttendees["1"].length : 0,
      })}
    </p>
  );
};

const StartEndTime = () => {
  const { data: gathering } = useGathering();

  if (!gathering.ID) {
    return null;
  }

  if (!(gathering.Date && gathering.Start && gathering.End)) {
    return null;
  }

  const date = gathering.Date ? dayjs(gathering.Date) : false;
  const start = getMomentFromString(false, gathering.Start);
  const end = getMomentFromString(false, gathering.End);

  return (
    <Fragment>
      <Badge secondary className="mb-2 mr-2">
        {date.format("dddd")}
      </Badge>
      <Badge secondary className="mb-2 mr-2">{`${start.format(
        "HH:mm"
      )} - ${end.format("HH:mm")}`}</Badge>
    </Fragment>
  );
};

const TimeAndLocationCard = React.memo(() => {
  const { t } = useTranslation(["gatherings"]);
  const {
    location: { pathname },
  } = useHistory();

  const {
    gathering: { Date, Start, End, LocationTitle },
    reservation: Reservation,
    isRoomReservationSet,
  } = useGatheringContext();

  const isEditable = useGatheringIsReservationEditable();

  const link = useMemo(() => {
    //! Wait for request
    if (isRoomReservationSet) {
      return `${pathname}/reservation/${Reservation.ID}`;
    }

    return `${pathname}/timeandlocation`;
  }, [Reservation, isRoomReservationSet, pathname]);

  const isEmpty = useMemo(
    () => ![Date, Start, End, LocationTitle].filter(Boolean).length,
    [Date, Start, End, LocationTitle]
  );

  const children = useMemo(
    () => (
      <Fragment>
        <h2 className="text-dark">{t("gatherings:timeandlocation")}</h2>
        {isEmpty ? (
          <p className="mb-0 text-gray">
            {t("gatherings:timeandlocation_empty")}
          </p>
        ) : (
          <div className="d-flex flex-wrap">
            <StartEndTime />
            <Badge secondary className="mb-2 mr-2">
              {LocationTitle}
            </Badge>
          </div>
        )}
      </Fragment>
    ),
    [LocationTitle, isEmpty, t]
  );

  return (
    <CardLink disabled={!isEditable} to={link}>
      {children}
    </CardLink>
  );
});

const EntertainmentCard = () => {
  const { t } = useTranslation(["gatherings"]);

  const { url } = useRouteMatch();

  const { data: gathering } = useGathering();

  const isEditable = useGatheringIsEditablePermission();
  const { isRoomReservationSet } = useGatheringContext();

  if (isRoomReservationSet) {
    return null;
  }

  const children = (
    <Fragment>
      <h2 className="text-dark">{t("gatherings:entertainment.title")}</h2>
      <p className="text-dark">
        {t(
          `gatherings:entertainment.label.Entertainment.${gathering.Entertainment}`
        )}
      </p>
      {gathering.EntertainmentDesc &&
        parseHTML(gathering.EntertainmentDesc || "")}
    </Fragment>
  );

  return (
    <CardLink disabled={!isEditable} to={`${url}/entertainment`}>
      {children}
    </CardLink>
  );
};

const GatheringEdit = ({ history: { replace }, match: { url } }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const { data: gathering } = useGathering();

  const canEdit = useGatheringIsEditablePermission();

  const [showDialog, setShowDialog] = useState(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const organizedByString = useMemo(() => {
    let organizedBy = "";

    if (gathering.OrganizedByTitle) {
      organizedBy = gathering.OrganizedByTitle;
    } else {
      if (gathering.Featured) {
        organizedBy = t("gatherings:edit:featured") + " ";
      }

      organizedBy += gathering.Exhibitor.Title;
    }

    return organizedBy;
  }, [t, gathering]);

  const descHTML = () => {
    return (
      <Fragment>
        <h2 className="text-dark">{t("gatherings:topic")}</h2>
        {!!(gathering.Description || gathering.Title) ? (
          <Fragment>
            {gathering.Title && <p className="text-dark">{gathering.Title}</p>}
            {gathering.Description && parseHTML(gathering.Description || "")}
          </Fragment>
        ) : (
          <p className="text-gray mb-0">{t("gatherings:topic_empty")}</p>
        )}
      </Fragment>
    );
  };

  const speakersHTML = () => {
    const speakersChildren = filter(
      map(get(gathering, "Speakers.edges", []), "node.FullName"),
      Boolean
    ).join(", ");
    const hasSpeakers =
      typeof speakersChildren === "string" && !!speakersChildren.length;

    return (
      <Fragment>
        <h2 className="text-dark">{t("gatherings:speakers")}</h2>

        {hasSpeakers ? (
          <p className="text-dark mb-0">{speakersChildren}</p>
        ) : (
          <p className="text-gray mb-0">{t("gatherings:speakers_empty")}</p>
        )}
      </Fragment>
    );
  };

  const moderatorsHTML = () => {
    const moderatorChildren = get(gathering, "Moderator.Member.FullName");
    const hasModerator = !!(
      typeof moderatorChildren === "string" && !!moderatorChildren.length
    );

    return (
      <Fragment>
        <h2 className="text-dark">{t("gatherings:moderator")}</h2>

        {hasModerator ? (
          <p className="text-primary mb-0">{moderatorChildren}</p>
        ) : (
          <p className="text-gray mb-0">{t("gatherings:moderator_empty")}</p>
        )}
      </Fragment>
    );
  };

  const attendeesHTML = () => {
    return (
      <Fragment>
        <h2 className="text-dark">{t("gatherings:participation")}</h2>
        <AttendeesList />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <GatheringBreadcrumbs />
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-start justify-content-between">
                <h1 className="flex-1">
                  {gathering.Title
                    ? gathering.Title
                    : gathering.ID
                    ? `${t("gatherings:gathering")} ${gathering.ID}`
                    : ""}
                </h1>
                <GatheringMenu onMenuClick={toggleDialog} />
              </div>
              <p className="mb-0">
                {t("gatherings:organizedby")} {organizedByString}
              </p>
              <div className="pt-2">
                <CurrentStage stage={gathering.CurrentStage} top={false} />
              </div>
              {gathering.CurrentStage === "LIVE" && (
                <div className="pt-2">
                  <LinkArrowExternal to={gathering.PublicLink}>
                    {t("gatherings:edit:publiclink")}
                  </LinkArrowExternal>
                </div>
              )}
            </div>

            <TimeAndLocationCard />

            <Fragment>
              <EntertainmentCard />

              <CardLink disabled={!canEdit} to={`${url}/description`}>
                {descHTML()}
              </CardLink>

              <CardLink
                disabled={
                  !(ENABLE_GATHERING_ATTACHING_SPEAKERS ? canEdit : false)
                }
                to={`${url}/speakers`}
              >
                {speakersHTML()}
              </CardLink>

              <CardLink disabled>{moderatorsHTML()}</CardLink>

              <CardLink disabled={!canEdit} to={`${url}/registration`}>
                {attendeesHTML()}
              </CardLink>

              <CardLink disabled={!canEdit} to={`${url}/categories`}>
                <h2 className="text-dark">{t("gatherings:categories")}</h2>
                <TypeAndTagsList />
              </CardLink>
            </Fragment>
          </div>

          <RemoveDialog
            replace={replace}
            gathering={gathering}
            open={showDialog}
            onMenuClick={toggleDialog}
          />
        </div>
      </div>
    </Fragment>
  );
};

export const GatheringBreadcrumbs = React.memo((props) => {
  const { children } = props;

  const { t } = useTranslation(["gatherings"]);

  const { data: gathering } = useGathering();

  const gatheringTitle = useMemo(() => {
    return gathering.Title
      ? gathering.Title
      : gathering.ID
      ? `#${gathering.ID}`
      : "";
  }, [gathering.ID, gathering.Title]);

  return (
    <Breadcrumbs>
      <Breadcrumbs.List>
        <Breadcrumbs.ListItem to="/gathering">
          {t("gatherings:gatherings")}
        </Breadcrumbs.ListItem>
        <Breadcrumbs.ListItem
          to={!!children ? `/gathering/${gathering.ID}` : undefined}
        >
          {gatheringTitle}
        </Breadcrumbs.ListItem>
        {children}
      </Breadcrumbs.List>
    </Breadcrumbs>
  );
});

const GatheringManagerRoutes = React.memo(({ match: { path } }) => {
  const hasPermission = useGatheringIsEditablePermission();

  if (!hasPermission) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route
        exact
        path={`${path}/description`}
        component={GatheringDescription}
      />
      <Route
        exact
        path={`${path}/categories`}
        component={GatheringCategories}
      />
      <Route
        exact
        path={`${path}/entertainment`}
        component={GatheringEntertainmentRoute}
      />
      <Route
        exact
        path={`${path}/registration`}
        component={GatheringRegistration}
      />
      <Route
        exact
        path={`${path}/registrationtype`}
        component={GatheringRegistrationType}
      />
      <Route
        exact
        path={`${path}/timeandlocation`}
        component={GatheringTimeAndLocation}
      />
      {ENABLE_GATHERING_ATTACHING_SPEAKERS && (
        <Fragment>
          {/*  <Route
            exact
            path={`${path}/speakers`}
            component={GatheringSpeakers}
          /> */}
          <Route exact path={`${path}/speakers`} component={Speakers} />
          <Route
            exact
            path={`${path}/speakers/invite`}
            component={SpeakersInvite}
          />
        </Fragment>
      )}
      <Redirect to={path} />
    </Switch>
  );
});

const RoomReservationRoute = React.memo(({ match: { path } }) => {
  return (
    <RoomReservationProvider>
      <Switch>
        <Route exact path={`${path}`} component={RoomReservationOverview} />
        <Route
          exact
          path={`${path}/reservation`}
          component={RoomReservationEdit}
        />
        <Redirect to={path} />
      </Switch>
    </RoomReservationProvider>
  );
});

// /gathering/:ID
const GatheringRoute = React.memo(({ match: { path, url } }) => {
  return (
    <DefaultLayout>
      <GatheringProvider>
        <Switch>
          <Route exact path={path} component={GatheringEdit} />
          <RoleRoute
            path={path}
            component={GatheringManagerRoutes}
            roles={["MANAGER"]}
            redirect={url}
          />
          <Redirect to={path} />
        </Switch>
      </GatheringProvider>
    </DefaultLayout>
  );
});

const GatheringRoomReservationRoute = React.memo(({ match: { path } }) => {
  return (
    <RoomReservationProvider>
      <Switch>
        <Route exact path={path} component={RoomReservationEdit} />
        <Route
          exact
          path={`${path}/cancel`}
          component={RoomReservationCancel}
        />
        <Route
          exact
          path={`${path}/complete`}
          component={RoomReservationComplete}
        />
        <Redirect to={path} />
      </Switch>
    </RoomReservationProvider>
  );
});

// /gathering/:ID
const GatheringEditWrapper = React.memo(({ match: { path, url } }) => {
  return (
    <Switch>
      <MainExhibitorRoute
        path={`${path}/reservation/:ReservationID`}
        component={GatheringRoomReservationRoute}
        roles={["MANAGER"]}
        redirect={url}
      />
      <MainExhibitorRoute
        path={`${path}/timeandlocation/rooms`}
        component={RoomReservationRoute}
        roles={["MANAGER"]}
        redirect={`${url}/timeandlocation`}
      />
      <Route path={`${path}`} component={GatheringRoute} />
      <Redirect to={path} />
    </Switch>
  );
});

export default GatheringEditWrapper;
