import React, { Fragment, useState, useMemo } from "react";

/* packages */
import parseHTML from "html-react-parser";
import useToggle from "react-use/lib/useToggle";

import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { Switch, Link, Redirect, Route } from "react-router-dom";
import { get, map, unionBy, some, filter } from "lodash";

import DefaultLayout from "../../layout/DefaultLayout";

/* components */
import Icon from "../../components/Icon";
import Breadcrumbs from "../../components/Breadcrumbs";
import ContactBlock from "../../components/ContactBlock";

/* context */
import { useEvent } from "../../contexts/EventContext";

/* graphql */
import {
  READ_PROVADA_FAQ_ITEMS,
  READ_PROVADA_FAQ_CATEGORIES,
  READ_ONE_PROVADA_FAQ_ITEM,
} from "../../graphql/FAQ";

const fields = ["Title", "Content"];

const HouseRulesPage = React.memo(() => {
  const { t } = useTranslation(["dashboard", "houseRules"]);

  const Event = useEvent();

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-body">
            <h1 className="mb-4">
              {!!Event && !!Event.HouseRulesTitle
                ? Event.HouseRulesTitle
                : t("dashboard:rules.title")}
            </h1>
            {!!Event && !!Event.HouseRulesContent && (
              <div>{parseHTML(Event.HouseRulesContent || "")}</div>
            )}
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-body">
            <h1>{t("houseRules:contact.title")}</h1>
            <p className="mb-3">{t("houseRules:contact.description")}</p>
            <ContactBlock />
          </div>
        </div>
      </div>
    </div>
  );
});

const FAQItemPage = React.memo(
  ({
    match: {
      params: { ID },
    },
    history: { replace },
  }) => {
    const { t } = useTranslation(["common", "standservices"]);

    const { data: itemData } = useQuery(READ_ONE_PROVADA_FAQ_ITEM, {
      variables: { ID: ID },
      onCompleted: ({ readOneProvadaFAQItem }) => {
        if (!readOneProvadaFAQItem) {
          return replace("/help");
        }
      },
      onError: () => replace("/help"),
    });

    const { Title, Content } = useMemo(
      () =>
        get(itemData, "readOneProvadaFAQItem") || {
          ID: null,
          Title: "",
          Content: "",
        },
      [itemData]
    );

    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h1 className="mb-4">{Title}</h1>
              <div>{parseHTML(Content || "")}</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <p className="mb-3">{t("common:contactblock")}</p>
              <ContactBlock />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const SearchFAQItem = React.memo(({ item: { ID, Title } }) => (
  <Link to={`/help/${ID}`} className="text-decoration-none">
    <div className="border-bottom">
      <div className="card-body">
        <p className="mb-0">{Title}</p>
      </div>
    </div>
  </Link>
));

SearchFAQItem.defaultProps = {
  item: {
    ID: null,
    Title: "",
  },
};

const FAQItem = React.memo(({ item: { ID, Title } }) => (
  <Link to={`/help/${ID}`} className="text-decoration-none">
    <div className="px-4 py-3 border-bottom">{Title}</div>
  </Link>
));

FAQItem.defaultProps = {
  item: {
    ID: null,
    Title: "",
  },
};

const FAQCategoryItem = React.memo(({ item: { ID, Title, Items } }) => {
  const [visible, toggle] = useToggle(false);

  return (
    <Fragment>
      <input
        id={`Category-${ID}`}
        type="checkbox"
        className="accordion-item-input d-none"
        onChange={() => toggle()}
        checked={visible}
        value={Title}
        name={`Category-${ID}`}
      />
      <div className="accordion-item border-bottom">
        <label
          htmlFor={`Category-${ID}`}
          className="p-3 flex-1 d-flex justify-content-between mb-0 border-bottom"
        >
          <h2 className="mb-0">{Title}</h2>
          <div className="accordion-arrow flex-center">
            <Icon className="font-size-1-5" iconType="ArrowDropLeft" />
          </div>
        </label>
        {visible && Items.map((item) => <FAQItem key={item.ID} item={item} />)}
      </div>
    </Fragment>
  );
});

FAQCategoryItem.defaultProps = {
  item: {
    ID: null,
    Title: "",
    Items: [],
  },
};

const TopFAQ = React.memo(() => {
  const { data } = useQuery(READ_PROVADA_FAQ_ITEMS, {
    variables: { sortBy: [{ direction: "DESC", field: "Views" }], limit: 5 },
  });

  const items = useMemo(
    () => map(get(data, "readProvadaFAQItems.edges", []), "node"),
    [data]
  );

  if (!(Array.isArray(items) && items.length)) {
    return null;
  }

  return items.map(({ ID, Title }) => {
    return (
      <Link key={ID} to={`/help/${ID}`} style={{ textDecoration: "none" }}>
        <div className="border-bottom">
          <div className="card-body">
            <p className="mb-0">{Title}</p>
          </div>
        </div>
      </Link>
    );
  });
});

const HelpPage = React.memo(() => {
  const { t } = useTranslation(["common", "helpcenter", "standservices"]);

  const [search, setSearch] = useState("");

  const onInputChange = ({ target: { value } }) => setSearch(value);

  const { data: categoriesData } = useQuery(READ_PROVADA_FAQ_CATEGORIES);

  // ! Array of all items flattened
  const Items = useMemo(
    () =>
      unionBy(
        ...map(
          map(
            get(categoriesData, "readProvadaFAQCategories.edges", []),
            "node.Items.edges"
          ),
          (item) => map(item, "node")
        ),
        "ID"
      ),
    [categoriesData]
  );

  //! Array of Categories with Items flattened inside
  const Categories = useMemo(
    () =>
      filter(
        map(
          map(
            get(categoriesData, "readProvadaFAQCategories.edges", []),
            "node"
          ),
          ({ Items, ...rest }) => ({
            ...rest,
            Items: map(get(Items, "edges"), "node"),
          })
        ),
        ({ Items }) => Items.length > 0
      ),
    [categoriesData]
  );

  const ItemsFiltered = useMemo(() => {
    if (search.length < 3) {
      return [];
    }

    return Items.filter((data) =>
      some(
        fields,
        (key) => data[key].toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    );
  }, [Items, search]);

  return (
    <Fragment>
      <div className="row">
        <div className="order-md-1 col-12 ">
          <div className="mb-4 px-3">
            <div className="form-group">
              <input
                type="text"
                value={search}
                onChange={onInputChange}
                className="form-control rounded-pill"
                placeholder={t("helpcenter:search")}
              />
            </div>
          </div>
        </div>

        <div className="order-md-3 col-12 col-md-6">
          {search.length >= 3 ? (
            <div className="card">
              <div className="card-body">
                <h2 className="mb-0">{t("helpcenter:results")}</h2>
              </div>
              {ItemsFiltered.map((item) => (
                <SearchFAQItem key={item.ID} item={item} />
              ))}
            </div>
          ) : (
            <div className="card">
              <div className="card-body border-bottom-0">
                <h1>{t("helpcenter:categoryblock")}</h1>
                <p>{t("helpcenter:categoryblockdescription")}</p>
              </div>
              {Categories.map((item) => (
                <FAQCategoryItem key={item.ID} item={item} />
              ))}
            </div>
          )}
        </div>

        <div className="order-md-2 col-12">
          <div className="card">
            <div className="card-body">
              <h1>{t("standservices:needhelp")}</h1>
              <p className="mb-3">{t("common:contactblock")}</p>
              <ContactBlock />
            </div>
          </div>
        </div>

        <div className="order-md-4 col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="mb-0">{t("helpcenter:top5questions")}</h2>
            </div>
            <TopFAQ />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

const HelpPageRoutes = React.memo(({ match: { path } }) => {
  const { t } = useTranslation(["helpcenter"]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem>{t("helpcenter:title")}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <p className="mb-0">{t("helpcenter:breadcrumb")}</p>
      </Breadcrumbs>

      <Switch>
        <Route exact path={path} component={HelpPage} />
        <Route exact path={`${path}/:ID(\\d+)`} component={FAQItemPage} />
        <Route exact path={`${path}/houserules`} component={HouseRulesPage} />
        <Route path={`${path}/:ID`} component={FAQItemPage} />
        <Redirect to={path} />
      </Switch>
    </DefaultLayout>
  );
});

export default HelpPageRoutes;
