import React, { useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";

import { Redirect, Switch, Route, Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { getMomentFromString } from "../../lib/common";

/* components */
import Badge from "../../components/Badge";
import { useSnackbarCustom } from "../../components/Notification";
import CurrentStage from "../../components/CurrentStage";
import LinkLikeArrow from "../../components/LinkLikeArrow";

import RoleBlock from "../../components/RoleBlock";
import MainExhibitorRoute from "../../components/MainExhibitorRoute";

/* context */
import { useEvent } from "../../contexts/EventContext";
import {
  useAttendeeExhibitor,
  useExhibitorGatherings,
} from "../../contexts/ExhibitorContext";
import { RoomReservationProvider } from "../../contexts/RoomReservationContext";

/* pages */
import GatheringEdit from "./GatheringEdit";

import RoomReservationEdit from "../RoomReservationEdit";
import RoomReservationOverview from "../Calendar";

import DefaultLayout from "../../layout/DefaultLayout";

import { GET_EXHIBITOR_FOR_DASHBOARD } from "../../graphql/User";
import {
  GET_EXHIBITOR_GATHERINGS,
  CREATE_NEW_GATHERING,
} from "../../graphql/Gathering";

const GatheringsList = (props) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const { data } = props;

  const renderListItem = useCallback(
    ({ node: gathering }) => {
      const title = gathering.Title ? gathering.Title : `#${gathering.ID}`;

      const date = gathering.Date ? dayjs(gathering.Date) : false;
      const start = getMomentFromString(false, gathering.Start);
      const end = getMomentFromString(false, gathering.End);

      return (
        <div className="col-12 col-md-6 d-flex" key={gathering.ID}>
          <div className="card w-100">
            <Link
              to={`/gathering/${gathering.ID}`}
              className={`card-body text-decoration-none`}
            >
              <CurrentStage top stage={gathering.CurrentStage} />
              <h2 className="flex-1 text-dark">{title}</h2>
              <div className="d-flex flex-wrap">
                {!!date && (
                  <Badge secondary className="mb-2 mr-2">
                    {date.format("dddd")}
                  </Badge>
                )}
                {!!(start && end) && (
                  <Badge secondary className="mb-2 mr-2">
                    {`${start.format("HH:mm")} – ${end.format("HH:mm")}`}
                  </Badge>
                )}
                {!!gathering.LocationTitle && (
                  <Badge secondary className="mb-2 mr-2">
                    {gathering.LocationTitle}
                  </Badge>
                )}
              </div>
              <p className="mb-0 text-dark">
                {!!gathering.Type && <span>{`${gathering.Type}`}, </span>}
                <span>{`${t("gatherings:registrationcount", {
                  count: gathering.Participants,
                })}`}</span>
              </p>
              <LinkLikeArrow>{t("common:details")}</LinkLikeArrow>
            </Link>
          </div>
        </div>
      );
    },
    [t]
  );

  if (!(Array.isArray(data) && data.length > 0)) {
    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="card-body">
              <p className="mb-0">{t("gatherings:nogatherings")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div className="row">{data.map(renderListItem)}</div>;
};

const GatheringsOverview = ({ history: { push }, match: { path } }) => {
  const { t, i18n } = useTranslation(["common", "gatherings"]);

  const Event = useEvent();
  const Exhibitor = useAttendeeExhibitor();

  const snackbar = useSnackbarCustom();

  const { gatherings, subExLoading, gatheringLoading } =
    useExhibitorGatherings();

  const [createNew, { loading: creatingNew }] = useMutation(
    CREATE_NEW_GATHERING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EXHIBITOR_GATHERINGS,
          variables: {
            Filter: {
              ExhibitorID__eq: Exhibitor.ID,
              EventID__eq: Event.ID,
            },
          },
        },
        {
          query: GET_EXHIBITOR_FOR_DASHBOARD,
          variables: {
            ID: Exhibitor.ID,
          },
        },
      ],
    }
  );

  const onBeforeCreateGAthering = (e) => {
    e.preventDefault();

    if (creatingNew) {
      return false;
    }

    snackbar.enqueueSnackbar(t("gatherings:creatingnew"));

    const options = {
      variables: {
        Input: {
          EventID: Event.ID,
          ExhibitorID: Exhibitor.ID,
          Language: i18n.language === "en" ? "en_US" : "nl_NL",
        },
      },
    };

    return createNew(options)
      .then(({ data: { createProvadaGathering } }) => {
        push(`/gathering/${createProvadaGathering.ID}`);
      })
      .catch((error) => {
        //@predrag.ristic Handle this!
      });
  };

  return (
    <DefaultLayout>
      <div className="mb-4 px-3">
        <div className="mb-4">
          <p className="h2 mb-0">
            {t("common:gatherings_lower_interval", {
              postProcess: "interval",
              count: gatherings.length,
            })}
          </p>
          {!!(Exhibitor && Event.ID) && (
            <p className="mb-0">
              {Exhibitor.Title} - {!!Event.ID && Event.Title}
            </p>
          )}
        </div>
      </div>

      <RoleBlock roles={["MANAGER"]}>
        <div className="card">
          <div
            className="card-body text-decoration-none"
            onClick={onBeforeCreateGAthering}
          >
            <h2 className="flex-1 text-dark">
              {t("gatherings:organizegathering")}
            </h2>
            <p className="text-dark">{t("gatherings:organizetext")}</p>
            <LinkLikeArrow>{t("gatherings:makenewgathering")}</LinkLikeArrow>
          </div>
          <Link to={`${path}/rooms`} className="card-body text-decoration-none">
            <h2 className="flex-1 text-dark">{t("gatherings:bookaroom")}</h2>
            <p className="text-dark">{t("gatherings:bookroomtext")}</p>
            <LinkLikeArrow>{t("gatherings:checkaviability")}</LinkLikeArrow>
          </Link>
        </div>
      </RoleBlock>

      <GatheringsList data={gatherings} />
    </DefaultLayout>
  );
};

const RoomReservation = React.memo(({ match: { path, url } }) => {
  return (
    <RoomReservationProvider>
      <Switch>
        <Route exact path={`${path}`} component={RoomReservationOverview} />
        <MainExhibitorRoute
          exact
          path={`${path}/reservation`}
          component={RoomReservationEdit}
          roles={["MANAGER"]}
          redirect={url}
        />
        <Redirect to={path} />
      </Switch>
    </RoomReservationProvider>
  );
});

// /gathering
const GatheringWrapper = React.memo(({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={path} component={GatheringsOverview} />
      <Route path={`${path}/rooms`} component={RoomReservation} />
      <Route path={`${path}/:ID`} component={GatheringEdit} />
      <Redirect to={path} />
    </Switch>
  );
});

export default GatheringWrapper;
